<template>
    <div
        v-if="text != null"
        class="px-2 py-[2px] rounded-full text-xs font-medium text-center"
        :class="style"
    >
        {{ text }}
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue"
import { BadgeColour } from "../types"

export default defineComponent({
    name: "EaiBadge",
    props: {
        colour: {
            type: String as PropType<BadgeColour>,
            default: "default",
        },
        text: String,
    },
    computed: {
        style() {
            if (this.colour === "primary")
                return "bg-primary-50 text-primary-700"
            if (this.colour === "warning")
                return "bg-warning-100 text-warning-500"
            if (this.colour === "success")
                return "bg-success-50 text-success-700"
            if (this.colour === "error") return "bg-error-50 text-error-700"
            return "bg-gray-100 text-gray-700"
        },
    },
})
</script>
