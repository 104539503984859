<template>
    <!-- FIXME: active should triggered focus instead-->
    <div
        class="flex flex-col relative self-stretch"
        @mouseleave="active = false"
    >
        <span
            class="text-sm font-medium itemLabel"
            v-if="itemLabel"
            >{{ itemLabel }}</span
        >
        <div v-click-away="onClickAway">
            <div
                class="relative flex grow justify-between align-items self-stretch"
                @click="searchable ? open() : toggle()"
            >
                <slot
                    name="display"
                    :active="active"
                    :selected-value="selectedValue"
                >
                    <span v-if="selectedValue">
                        {{ selectedValue?.name || selectedValue }}
                    </span>
                    <span v-else>{{ placeholder }}</span>
                </slot>
                <slot
                    name="toggler"
                    :active="active"
                >
                    <VueFeather
                        class="cursor-pointer"
                        :type="active ? 'chevron-down' : 'chevron-up'"
                    />
                </slot>
            </div>
            <transition name="dropup-content">
                <div
                    v-show="active"
                    class="absolute left-0 bottom-[46px] flex flex-col grow-0 max-h-[400px] justify-start py-1 overflow-y-scroll w-full z-5"
                    :class="customStyle"
                    ref="dropupContent"
                >
                    <slot name="content">
                        <span v-if="items.length > 0">
                            <EaiDropupItem
                                v-for="item in items"
                                :value="item"
                                :key="item?.id || item"
                                @click="onSelection(item)"
                                :selected="selectedValue"
                            >
                            </EaiDropupItem>
                        </span>
                        <EaiDropupItem
                            v-else
                            value="No results"
                            key="-1"
                        >
                        </EaiDropupItem>
                    </slot>
                    <slot name="extra-content"></slot>
                </div>
            </transition>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, nextTick, ref, watch } from "vue"
import { defineComponent } from "vue"
import EaiDropupItem from "./EaiDropupItem.vue"
// import type { } from "../types"
import VueFeather from "vue-feather"

export interface Props {
    items:
        | Array<{ name: [string, number]; id: any; [key: string]: any }>
        | Array<string>
    open?: boolean | null
    placeholder?: string
    itemLabel?: string
    selected:
        | string
        | { name: [string, number]; id: any; [key: string]: any }
        | null
    customStyle?: string
    searchable?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    items: () => [],
    placeholder: "Select from dropup",
    selected: null,
})

const emit = defineEmits<{
    (
        e: "update:selected",
        selected:
            | { name: [string, number]; id: any; [key: string]: any }
            | string
    ): void
}>()

const active = ref(props.open)
const dropupContent = ref<HTMLBodyElement | null>(null)

watch(
    () => props.open,
    (newValue, oldValue) => {
        active.value = newValue
    }
)

const selectedValue = computed({
    get() {
        return props.selected
    },
    set(
        value: { name: [string, number]; id: any; [key: string]: any } | string
    ) {
        emit("update:selected", value)
    },
})

const scrollToBottom = () => {
    nextTick(() => {
        if (dropupContent.value) {
            dropupContent.value.scrollTop = dropupContent.value.scrollHeight
        }
    })
}
const toggle = () => {
    active.value = !active.value
    scrollToBottom()
}

const open = () => {
    active.value = true
    scrollToBottom()
}

const onClickAway = () => (active.value = false)
const onSelection = (
    item: { name: [string, number]; id: any; [key: string]: any } | string
) => {
    selectedValue.value = item
    toggle()
}
</script>

<style scoped>
.dropup-content-enter-active,
.dropup-content-leave-active {
    transition: all 0.2s;
}

.dropup-content-enter,
.dropup-content-leave-to {
    opacity: 0;
    transform: translateY(-5px);
}
</style>
