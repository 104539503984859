<template>
    <div>
        <div
            class="flex flex-row items-center h-6"
            :class="props.padding"
        >
            <h6 class="grow text-sm font-medium text-gray-800">
                <slot name="header">
                    {{ header }}
                </slot>
            </h6>
            <VueFeather
                type="chevron-down"
                class="cursor-pointer"
                stroke="var(--gray-800)"
                :class="{ '-rotate-90': state.collapsed }"
                @click="toggleCollapsedStatus"
            />
        </div>
        <div v-show="!state.collapsed">
            <slot name="body"></slot>
        </div>
    </div>
</template>

<script setup lang="ts">
import { reactive, watch } from "vue"
import VueFeather from "vue-feather"

const props = withDefaults(
    defineProps<{
        padding?: string
        collapsed?: boolean
    }>(),
    {
        padding: "py-3 pr-4 pl-2",
    }
)
watch(
    () => props.collapsed,
    (newState) => {
        return (state.collapsed = newState)
    }
)

const state = reactive({
    collapsed: props.collapsed,
})

const toggleCollapsedStatus = () => {
    state.collapsed = !state.collapsed
}
</script>
