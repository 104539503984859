/**
 * Returns a human-readable relative time between now and the given date.
 * @param date - The date to compare to now.
 * @param options - The options to pass to the Intl.RelativeTimeFormat constructor.
 * @returns A string representing the relative time between now and the given date.
 */
export const getRelativeTimeString = (
    date: Date | string,
    options: Intl.RelativeTimeFormatOptions = { style: "short" }
) => {
    // Convert the input to a Date object if it is a string
    if (typeof date === "string") date = new Date(date)

    const now = new Date()
    const elapsed = now.getTime() - date.getTime()
    const seconds = Math.round(Math.abs(elapsed) / 1000)
    const minutes = Math.round(seconds / 60)
    const hours = Math.round(minutes / 60)
    const days = Math.round(hours / 24)

    const rtf = new Intl.RelativeTimeFormat("en", options)

    const timeSign = elapsed > 0 ? -1 : 1

    if (seconds < 60) {
        return "just now"
    } else if (minutes < 60) {
        return rtf.format(timeSign * minutes, "minute")
    } else if (hours < 24) {
        return rtf.format(timeSign * hours, "hour")
    } else {
        return rtf.format(timeSign * days, "day")
    }
}

/**
 * Formats the given date as a string in the format "YYYY-MM-DD" or "YYYY-MM-DD HH:SS".
 * @param date - The date to format.
 * @param withTime - Whether to include the time in the output.
 * @returns A string representing the given date in the format "YYYY-MM-DD HH:SS".
 */
export const formatDate = (date: Date | string, withTime = true) => {
    // Convert the input to a Date object if it is a string
    if (typeof date === "string") date = new Date(date)

    const year = date.getFullYear()
    const month = `${date.getMonth() + 1}`.padStart(2, "0")
    const day = `${date.getDate()}`.padStart(2, "0")

    const dateStr = `${year}-${month}-${day}`
    if (!withTime) return dateStr

    const hour = `${date.getHours()}`.padStart(2, "0")
    const minute = `${date.getMinutes()}`.padStart(2, "0")

    // Format the date using the formatter
    return `${dateStr} ${hour}:${minute}`
}

/**
 * Returns a string representing the given UTC date in the user's local time zone.
 * The output format is "YYYY-MM-DD HH:MM".
 * @param utcDate - The UTC date to convert to the user's local time zone.
 * @returns A string representing the given UTC date in the user's local time zone.
 */
export const getLocalDatetimeStringFromUTC = (utcDate: Date | string) => {
    // Convert the input to a Date object if it is a string
    if (typeof utcDate === "string") utcDate = new Date(utcDate)

    // Convert the UTC date to the user's local time zone
    const localDate = new Date(
        utcDate.getTime() - utcDate.getTimezoneOffset() * 60000
    )

    return formatDate(localDate)
}
