<template>
    <nav
        class="fixed h-screen bg-primary-700 flex flex-col z-50 w-[--sidebar-size-collapsed] hover:w-[300px] group/navbar text-primary-300 text-md font-medium"
        @mouseover="isOpen = true"
        @mouseleave="isOpen = false"
    >
        <!-- LOGO -->
        <div class="bg-white">
            <img
                class="h-[78px] p-[23px] box-border hidden group-hover/navbar:inline"
                src="@stories/assets/icons/logo.svg"
            />
            <img
                class="h-[78px] p-[23px] box-border group-hover/navbar:hidden"
                src="@stories/assets/icons/logo_collapsed.svg"
            />
        </div>

        <div class="h-full flex flex-col pt-2.5 pb-4 px-4">
            <!-- MAIN NAVIGATION -->
            <ul class="flex flex-col justify-between gap-2">
                <NavItem
                    :to="{ name: 'dashboard' }"
                    icon-type="bar-chart-2"
                    label="Dashboard"
                />

                <NavItem
                    :to="{ name: 'documents-list' }"
                    icon-type="file"
                    label="Documents"
                />

                <NavItem
                    :to="{ name: 'output' }"
                    icon-type="download"
                    label="Output"
                />

                <!-- Configuration sub-menu -->
                <li
                    class="p-3 rounded-md transition-colors duration-200"
                    :class="
                        isConfigurationLinkActive
                            ? 'bg-primary-800'
                            : 'hover:bg-primary-800 group/configuration'
                    "
                >
                    <div class="flex flex-row gap-4">
                        <VueFeather
                            size="24px"
                            type="settings"
                        />
                        <div
                            class="hidden group-hover/navbar:flex flex-grow justify-between"
                        >
                            <span> Configuration </span>
                            <VueFeather
                                type="chevron-down"
                                size="24px"
                                class="group-hover/configuration:-rotate-180 transition-all duration-200 ease-in-out origin-center"
                            />
                        </div>
                    </div>
                    <ul
                        class="text-sm hidden flex-col items-start gap-4 mt-4 ml-10 [&>li:hover]:text-primary-400"
                        :class="
                            isConfigurationLinkActive
                                ? 'group-hover/navbar:flex'
                                : 'group-hover/configuration:!flex'
                        "
                    >
                        <li>
                            <RouterLink
                                :to="{ name: 'document-types' }"
                                active-class="text-primary-400"
                            >
                                Document Types
                            </RouterLink>
                        </li>
                        <li>
                            <RouterLink
                                :to="{ name: 'teams' }"
                                active-class="text-primary-400"
                            >
                                Teams
                            </RouterLink>
                        </li>
                        <li v-if="currentOrganization?.self_service">
                            <RouterLink
                                :to="{ name: 'billing' }"
                                active-class="text-primary-400"
                            >
                                Billing
                            </RouterLink>
                        </li>

                        <li v-if="isSuperAdmin || currentUserRole === 'admin'">
                            <RouterLink
                                :to="{ name: 'audit-log' }"
                                active-class="text-primary-400"
                            >
                                Audit Log
                            </RouterLink>
                        </li>

                        <li v-if="isSuperAdmin">
                            <RouterLink
                                :to="{ name: 'configuration' }"
                                active-class="text-primary-400"
                            >
                                Configuration
                            </RouterLink>
                        </li>
                        <li v-if="isSuperAdmin">
                            <RouterLink
                                :to="{ name: 'queue' }"
                                active-class="text-primary-400"
                            >
                                Queue
                            </RouterLink>
                        </li>
                    </ul>
                </li>
                <!--  -->

                <NavItem
                    :to="{ name: 'api-documentation' }"
                    icon-type="code"
                    label="API"
                />

                <NavItem
                    :to="{ name: 'notes-list' }"
                    icon-type="message-square"
                    label="Notes"
                />
            </ul>

            <!-- USER AND ORGANIZATION DETAILS -->
            <template v-if="isRegistrationComplete">
                <hr class="border-primary-600 mt-auto" />

                <OrganizationList
                    :is-open="isOpen"
                    class="my-1"
                />

                <hr class="border-primary-600" />

                <div class="p-3 flex flex-row items-center gap-4">
                    <VueFeather
                        size="24px"
                        type="user"
                    />
                    <span
                        class="hidden group-hover/navbar:flex justify-between flex-grow items-center"
                    >
                        {{ currentUser?.first_name || currentUser?.email }}
                        <EaiBadge
                            v-if="userRole"
                            :text="userRole"
                            colour="primary"
                        />
                    </span>
                </div>

                <EaiButton
                    class="ml-auto mt-2 invisible group-hover/navbar:visible"
                    size="small"
                    type="secondary"
                    @click="emit('logout')"
                >
                    Logout
                    <VueFeather
                        type="log-out"
                        size="20px"
                    />
                </EaiButton>
            </template>
        </div>
    </nav>
</template>

<script setup lang="ts">
import { computed, ref } from "vue"
import { storeToRefs } from "pinia"
import { useAuthStore } from "@/resources/stores/auth"
import EaiButton from "@stories/Button/Button.vue"
import OrganizationList from "@/organizations/OrganizationList.vue"
import EaiBadge from "@stories/Badge/EaiBadge.vue"
import NavItem from "./NavItem.vue"
import { useRoute } from "vue-router"

const emit = defineEmits<{ (e: "logout"): void }>()

const authStore = useAuthStore()
const {
    currentUser,
    currentUserRole,
    currentOrganization,
    isRegistrationComplete,
    isSuperAdmin,
} = storeToRefs(authStore)

const userRole = computed(() => {
    if (currentOrganization.value) {
        if (currentUserRole.value) return currentUserRole.value
        else if (isSuperAdmin.value) return "superadmin"
    }
    return undefined
})

const route = useRoute()
const isConfigurationLinkActive = computed(() => {
    return (
        route.name === "document-types" ||
        route.name === "teams" ||
        route.name === "billing" ||
        route.name === "configuration" ||
        route.name === "audit-log"
    )
})

// Note, this is only necessary because of the organization selection dropup
const isOpen = ref(false)
</script>
