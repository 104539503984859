<template>
    <template v-if="isLoggedIn">
        <NavBar
            class="fixed left-0 top-0"
            @logout="authStore.logout()"
        />

        <div
            class="content-grid absolute h-screen left-[--sidebar-size-collapsed] right-0 px-8"
        >
            <!-- FIXME: overflow-clip-margin only works in Chrome with overflow-clip on both axes -->
            <PageHeader
                v-if="route.name !== 'register'"
                class="content-grid-header mb-6 overflow-x-clip"
                style="overflow-clip-margin: 2rem"
            >
                <div
                    v-if="currentOrganization?.settings?.trial_end_date"
                    class="bg-warning-200/40 text-sm font-semibold text-gray-500 px-4 py-2 rounded-lg -mx-4 shadow-md w-full"
                >
                    Your trial will expire on:
                    {{ currentOrganization?.settings.trial_end_date }}
                </div>
                <EaiBreadcrumbs
                    :crumb-mapping="crumbMapping"
                    :home-name="currentOrganization?.name"
                />
            </PageHeader>

            <router-view
                :key="remount"
                class="content-grid-main top-0 left-0 right-0 bottom-0 overflow-y-auto overflow-x-hidden"
            />
        </div>
    </template>
    <template v-else>
        <RouterView class="absolute top-0 left-0 right-0 bottom-0" />
    </template>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia"
import { watchEffect, onMounted, onUnmounted } from "vue"
import { useAuthStore } from "./resources/stores/auth"
import NavBar from "./components/Nav/NavBar.vue"
import PageHeader from "./components/PageHeader.vue"
import EaiBreadcrumbs from "@stories/Breadcrumb/EaiBreadcrumbs.vue"
import { useCrumbs } from "@/resources/composables/crumbs"
import { ref } from "vue"
import { apiRequest } from "./resources/http/http"
import { useRoute } from "vue-router"
import { getLocalDatetimeStringFromUTC } from "./resources/utils/time"

const authStore = useAuthStore()
const { currentOrganization, currentUser, isLoggedIn } = storeToRefs(authStore)

const route = useRoute()
const { crumbMapping } = useCrumbs()

// When we change the organization, make sure we remount the view so that we force data
//  to be reloaded from backend.
const remount = ref(0)
watchEffect(() => {
    if (currentOrganization.value?.id && route.name !== "register")
        remount.value += 1
})

// User tracking
const sendTrackingRequest = async (event: MouseEvent | KeyboardEvent) => {
    await apiRequest.post("/user-actions", {
        event_type: event.type,
        url: document.location.href,
    })
}

watchEffect(() => {
    const shouldTrackUser =
        currentUser.value?.email?.endsWith("@evolution.ai") ||
        currentUser.value?.email?.endsWith("@labelyourdata.com")

    if (
        import.meta.env.VITE_USER_TRACKING_ENABLE === "true" &&
        shouldTrackUser
    ) {
        document.addEventListener("click", sendTrackingRequest)
        document.addEventListener("keyup", sendTrackingRequest)
    } else {
        document.removeEventListener("click", sendTrackingRequest)
        document.removeEventListener("keyup", sendTrackingRequest)
    }
})
onMounted(() => {
    if (window.Intercome) {
        window.Intercome("update", {
            name: currentUser.value?.first_name, // Full name
            email: currentUser.value?.email, // Email address
            created_at: getLocalDatetimeStringFromUTC(
                currentUser.value?.created_at
            ), // Signup date as a Unix timestamp
        })
    }
})

onUnmounted(() => {
    if (window.Intercome) {
        window.Intercom("shutdown")
    }
})
</script>

<style>
.content-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
        "header"
        "main";
}

.content-grid > .content-grid-header {
    grid-area: header;
}

.content-grid > .content-grid-main {
    grid-area: main;
}
</style>
