<template>
    <span
        v-if="currentValue"
        class="flex grow-0 justify-between items-center gap-2 py-[10px] px-[14px] text-left bg-transparent font-medium border-transparent rounded-md hover:text-primary-300 cursor-pointer"
        :class="{ 'text-primary-300': isSelected }"
    >
        <slot>
            <span>{{ currentValue }}</span>
            <VueFeather
                v-if="isSelected"
                type="check"
                stroke="var(--primary-600)"
                stroke-width="3px"
                size="20px"
            />
        </slot>
    </span>
</template>

<script setup lang="ts">
import { computed, ref } from "vue"
import VueFeather from "vue-feather"

interface Props {
    selected:
        | { name: [string, number]; id: any; [key: string]: any }
        | string
        | null
    value: { name: [string, number]; id: any; [key: string]: any } | string
}

const props = withDefaults(defineProps<Props>(), { selected: null, value: "" })
const currentValue = computed(() => {
    return props.value?.name || props.value || ""
})

const isSelected = computed(() => {
    if (props.selected === null) return false
    else {
        let chosen = props.selected?.id || props.selected
        let option = props.value?.id || props.value
        return chosen === option
    }
})
</script>
