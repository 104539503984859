import axios, {
    AxiosInstance,
    AxiosRequestConfig,
    AxiosRequestHeaders,
    Method,
} from "axios"
import { useAuthStore } from "../stores/auth"

// create axios instance for transcribe (private) api
const API_PREFIX: any = import.meta.env.VITE_API_BASEURL
const config: AxiosRequestConfig = {
    baseURL: `${API_PREFIX}api`,
    timeout: 1000 * 30,
    withCredentials: true,
}
const apiAxios: AxiosInstance = axios.create(config)

const methods: Array<Method> = ["post", "delete", "patch", "put"]

export class HTTPError extends Error {
    name: string
    code: number

    constructor(message: string, errorCode: number) {
        super(message)
        this.name = "HttpError"
        this.code = errorCode
    }
}

const errorInterceptor = (error: Error) => {
    const httpError = new HTTPError(
        error?.response?.data?.message || "error",
        error?.response?.status || 500
    )
    return Promise.reject(httpError)
}

apiAxios.interceptors.request.use(function (config: AxiosRequestConfig) {
    if (config.method ? methods.includes(config.method) : null) {
        const mainStore = useAuthStore()
        if (mainStore.csrfToken) {
            config.headers["X-CSRF-Token"] = mainStore.csrfToken
        }
    }
    return config
}, errorInterceptor)

apiAxios.interceptors.response.use((response) => response, errorInterceptor)

const apiRequestFull = async (
    method: Method,
    endpoint: string,
    payload?: object,
    headers?: AxiosRequestHeaders,
    ...args: any[]
) => {
    let data = null
    let params = null
    method == "get" && payload !== undefined
        ? (params = payload)
        : (data = payload)
    return apiAxios
        .request({ method, url: endpoint, params, data, headers, ...args })
        .then((response) => response)
}

const apiRequest = async (
    method: Method,
    endpoint: string,
    payload?: object,
    headers?: AxiosRequestHeaders,
    ...args: any[]
) => {
    const response = await apiRequestFull(
        method,
        endpoint,
        payload,
        headers,
        ...args
    )
    return response.data
}

apiRequest.get = apiRequest.bind(null, "get")
apiRequest.post = apiRequest.bind(null, "post")
apiRequest.put = apiRequest.bind(null, "put")
apiRequest.delete = apiRequest.bind(null, "delete")

apiRequestFull.get = apiRequestFull.bind(null, "get")
apiRequestFull.post = apiRequestFull.bind(null, "post")
apiRequestFull.put = apiRequestFull.bind(null, "put")
apiRequestFull.delete = apiRequestFull.bind(null, "delete")

export { apiRequest, apiRequestFull }
