/** @type {import('vue-router').RouterOptions['routes']} */

export const routes = [
    // HOME
    // We use this redirect so we can generally point a link to "home" and decide here
    //  to which view it should redirect.
    {
        path: "",
        name: "home",
        // TODO: redirect to dashboard once available
        redirect: { name: "documents-list" },
    },
    // LOGIN & REGISTRATION
    {
        path: "/login",
        name: "login",
        component: () => import("@/login/views/LoginView.vue"),
        meta: {
            requiresAuth: false,
            requiresAnonymous: true,
            metaTags: {
                name: "description",
                content:
                    "Log into Evolution AI’s acclaimed Transcribe platform.",
            },
        },
    },
    {
        path: "/setup",
        name: "register",
        component: () => import("@/setup/views/SetupMain.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    // DASHBOARD VIEWS
    {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/dashboard/views/DashboardMain.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    // DOCUMENTS VIEWS
    {
        path: "/documents",
        component: () => import("@/documents/views/DocumentList.vue"),
        name: "documents-list",
        meta: {
            requiresAuth: true,
            crumbs: [{ name: "Documents", to: { name: "documents-list" } }],
        },
    },
    {
        path: "/documents/:id",
        name: "document-view",
        component: () => import("@/documents/views/DocumentView.vue"),
        props: (route) => ({
            documentId: route.params.id,
            subdocumentIdx:
                "subdocument" in route.query
                    ? parseInt(route.query.subdocument)
                    : -1,
            pageNumber: parseInt(route.query?.p || 0),
        }),
        meta: {
            requiresAuth: true,
            crumbs: [
                { name: "Documents", to: { name: "documents-list" } },
                { name: "%document" },
            ],
        },
    },
    {
        path: "/documents/:id/annotate",
        name: "annotate-document",
        component: () => import("@/documents/views/AnnotateView.vue"),
        props: (route) => ({
            documentId: route.params.id,
            subdocumentIdx:
                "subdocument" in route.query
                    ? parseInt(route.query.subdocument)
                    : -1,
        }),
        meta: {
            requiresAuth: true,
            crumbs: [
                { name: "Documents", to: { name: "documents-list" } },
                { name: "%document" },
                { name: "Annotate" },
            ],
        },
    },
    {
        path: "/documents/:id/quick-fix",
        name: "quick-fix-document",
        component: () => import("@/documents/views/AnnotateView.vue"),
        props: (route) => ({
            documentId: route.params.id,
            subdocumentIdx:
                "subdocument" in route.query
                    ? parseInt(route.query.subdocument)
                    : -1,
            isQuickFix: true,
            quickFixHash: route.query.f,
            preselectedPageNumber: parseInt(route.query?.p || 0),
        }),
        meta: {
            requiresAuth: true,
            crumbs: [
                { name: "Documents", to: { name: "documents-list" } },
                { name: "%document" },
                { name: "Quick Fix" },
            ],
        },
    },
    {
        path: "/documents/:id/split",
        name: "split-document",
        component: () => import("@/documents/views/SplitDocumentView.vue"),
        props: (route) => ({
            documentId: route.params.id,
        }),
        meta: {
            requiresAuth: true,
            crumbs: [
                { name: "Documents", to: { name: "documents-list" } },
                { name: "%document" },
                { name: "Split" },
            ],
        },
    },
    // DOCUMENT TYPES VIEWS
    {
        path: "/document-types",
        name: "document-types",
        component: () => import("@/document-types/views/DocTypeMain.vue"),
        meta: {
            requiresAuth: true,
            crumbs: [{ name: "Document Types" }],
        },
    },
    // TEAMS VIEWS
    {
        path: "/teams",
        component: () => import("@/teams/views/TeamsMain.vue"),
        name: "teams",
        meta: {
            requiresAuth: true,
            crumbs: [{ name: "Team" }],
        },
    },
    // OUTPUT VIEWS
    {
        path: "/output",
        component: () => import("@/output/views/OutputTemplate.vue"),
        name: "output",
        meta: {
            requiresAuth: true,
            crumbs: [{ name: "Output" }],
        },
    },
    // NOTES VIEWS
    {
        path: "/notes",
        component: () => import("@/notes/views/NotesListView.vue"),
        name: "notes-list",
        meta: {
            requiresAuth: true,
            crumbs: [{ name: "Notes" }],
        },
    },
    // BILLING VIEW
    {
        path: "/billing",
        component: () => import("@/billing/views/BillingMain.vue"),
        name: "billing",
        meta: {
            requiresAuth: true,
            crumbs: [{ name: "Billing" }],
        },
    },
    // CONFIGURATION VIEW
    {
        path: "/configuration",
        component: () => import("@/configuration/views/ConfigurationMain.vue"),
        name: "configuration",
        meta: {
            requiresAuth: true,
            requiresSuperAdmin: true,
            crumbs: [{ name: "Configuration" }],
        },
    },
    // AUDIT LOG VIEW
    {
        path: "/audit-log",
        component: () => import("@/audit-log/views/AuditLogMain.vue"),
        name: "audit-log",
        meta: {
            requiresAuth: true,
            crumbs: [{ name: "Audit Log" }],
        },
    },
    // QUEUE VIEW
    {
        path: "/queue",
        component: () => import("@/queue/views/QueueMain.vue"),
        name: "queue",
        meta: {
            requiresAuth: true,
            requiresSuperAdmin: true,
            crumbs: [{ name: "Queue" }],
        },
    },
    // API DOCUMENTATION
    {
        path: "/api-documentation",
        component: () => import("@/api-documentation/views/ApiMain.vue"),
        name: "api-documentation",
        meta: {
            requiresAuth: true,
            crumbs: [{ name: "API documentation" }],
        },
    },
    // OTHER VIEWS
    {
        path: "/error/:code(\\d{3})?",
        name: "error",
        component: () => import("@/components/Error.vue"),
        meta: {
            requiresAuth: false,
        },
        props: (route) => ({
            code: parseInt(route?.params?.code ?? 500),
        }),
    },
    {
        // Match any other url and redirect to 404
        path: "/:pathMatch(.*)*",
        name: "not-found",
        component: () => import("@/components/Error.vue"),
        meta: {
            requiresAuth: false,
        },
        props: () => ({
            code: 404,
            title: "Page Not Found",
            message:
                "The page you are trying to access does not exist, have you typed a correct URL?",
        }),
    },
]
