<template>
    <button
        :class="style"
        v-bind="$attrs"
    >
        <slot />
    </button>
</template>

<script lang="ts">
export default {
    inheritAttrs: false,
}
</script>

<script setup lang="ts">
import { computed } from "vue"
import type { ButtonSize, ButtonType } from "../types"

export interface Props {
    size?: ButtonSize
    type?: ButtonType
    isIcon?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    size: "medium",
    type: "primary",
    isIcon: false,
})

const style = computed(() =>
    [
        `btn-${props.type}`,
        `btn-${props.size}`,
        props.isIcon ? "btn-icon" : "",
    ].join(" ")
)
</script>

<style lang="css">
@tailwind components;

@layer components {
    button,
    /* Default configuration corresponds to the medium size */
    button.btn-medium {
        @apply flex flex-row justify-center items-center gap-2;
        @apply rounded-lg border-solid border-[1px];
        @apply py-[10px] px-4;
        @apply !font-medium !text-sm; /* TODO: check where else it's defined */
        @apply focus:outline-none focus:ring-4 focus:ring-offset-0 focus:ring-[#f4ebff];
        @apply shadow-sm shadow-gray-900/5;
        @apply disabled:pointer-events-none;
    }

    /* TYPE MODIFIERS */
    button.btn-primary {
        @apply text-white  bg-primary-600 border-primary-700;
        @apply hover:bg-primary-700 hover:border-primary-700;
        @apply disabled:bg-primary-200 disabled:border-primary-200;
    }

    button.btn-secondary {
        @apply text-primary-700 bg-primary-50 border-primary-50;
        @apply hover:bg-primary-100 hover:border-primary-100;
        @apply disabled:text-primary-300 disabled:bg-primary-25 disabled:border-primary-25;
    }

    button.btn-tertiary {
        @apply text-gray-700 bg-white border-gray-300;
        @apply hover:bg-gray-50 focus:ring-gray-100;
        @apply disabled:border-gray-200 disabled:text-gray-300;
    }

    button.btn-toolbar {
        @apply bg-white border-white enabled:hover:bg-primary-50 text-gray-700 hover:text-primary-700;
        @apply disabled:text-gray-300;
    }

    /* SIZE MODIFIERS (button defaults to 'medium' so we don't need a modifier for that) */
    button.btn-icon {
        @apply p-[10px];
    }

    button.btn-large {
        @apply !text-md px-[18px] py-[10px];
    }

    button.btn-large.btn-icon {
        @apply p-3;
    }

    button.btn-small {
        @apply px-[14px] py-2;
    }

    button.btn-small.btn-icon {
        @apply p-2;
    }

    /* This is for building toolbars of EaiButtons. To use, wrap the buttons in a
        <div class="button-toolbar"> */
    div.button-toolbar {
        @apply flex gap-0 border rounded-xl overflow-clip;
    }

    div.button-toolbar > button {
        @apply !rounded-none !border-y-0 !ring-0 -mr-[1px] first:!border-l-0 last:!border-r-0;
    }
}
</style>
