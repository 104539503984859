import { createApp, App, Component } from "vue"
import { Router } from "vue-router"
import VueClickAway from "vue3-click-away"
import VueFeather from "vue-feather"
import { apiRequest } from "./resources/http/http"
import * as Sentry from "@sentry/vue"
import { BrowserTracing } from "@sentry/tracing"
import { createPinia } from "pinia"

const instantiateApp = (sfc: Component, router: Router): App => {
    const app = createApp(sfc)

    if (import.meta.env.VITE_SENTRY_ENABLE === "true") {
        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN,
            integrations: [
                new BrowserTracing({
                    routingInstrumentation:
                        Sentry.vueRouterInstrumentation(router),
                    tracingOrigins: [/^\//],
                }),
            ],
            environment: import.meta.env.VITE_ENVIRONMENT,
            initialScope: {
                tags: { app: "vue-v2" },
            },
            // TODO: setup
            // trackComponents: true,
            tracesSampleRate: 0.0,
        })
    }
    app.component("VueFeather", VueFeather)
    app.use(VueClickAway)
    app.use(createPinia())
    app.use(router)
    app.mount("#app")

    // make axios instance a global property, from a child component access as this.$http
    app.config.globalProperties.$http = apiRequest

    // injected properties are auto-unwrapped - no longer need `.value` in the next minor
    // release; this config is temporary and will not be needed in the future release
    app.config.unwrapInjectedRef = true

    return app
}

export { instantiateApp }
