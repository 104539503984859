export enum Role {
    admin = "admin",
    user = "user",
    annotator = "annotator",
}

export interface User {
    id: string
    email: string
    first_name: string | null
    last_name: string | null
    is_superadmin: boolean
    nr_projects: number
    registered: boolean
    created_at: string
}

export interface DocumentType {
    id?: string
    name?: string
}
export interface OrganizationSettings {
    has_sla: boolean
    confidence_threshold: number
    has_qa_workflow: boolean
    trial_end_date?: string | null
    webhook?: string | null
}

export interface Organization {
    document_types?: Array<DocumentType>
    documents?: number
    id?: string
    name?: string
    stages?: Array<string>
    metadata_column_list?: Array<string>
    settings?: OrganizationSettings
    self_service: boolean
}
