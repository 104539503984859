<template>
    <EaiDropup
        v-click-away="onClickAway"
        :items="organizations"
        :selected="currentOrganization"
        searchable
        placeholder="select"
        custom-style="bg-primary-800 border-transparent rounded-md text-sm lg:max-h-[400px] md:max-h-[180px]"
        class="p-3 self-stretch border-transparent rounded-md hover:bg-primary-800"
        :open="isOpen ? null : false"
        @update:selected="updateOrganization($event?.id)"
    >
        <template #extra-content>
            <div class="flex gap-1 items-stretch">
                <div class="px-3.5 py-2.5 border-t border-primary-600 w-full">
                    <div
                        v-if="isSuperAdmin"
                        class="flex justify-between items-start py-2 pl-1 pr-0"
                    >
                        <span class="grow text-sm text-primary-100">
                            Admin view
                        </span>
                        <input
                            v-model="adminView"
                            type="checkbox"
                        />
                    </div>

                    <EaiAccordionItem
                        :collapsed="true"
                        padding="py-2 pl-1 pr-0"
                    >
                        <template #header>
                            <span
                                class="grow text-md font-medium text-primary-100"
                            >
                                Add new project
                            </span>
                        </template>
                        <template #body>
                            <div class="flex flex-row gap-2 items-start mt-2">
                                <EaiInput
                                    ref="addOrganizationInput"
                                    v-model:value="newOrganizationName"
                                    class="w-4/5"
                                    placeholder="Enter name"
                                    @keyup.enter="addNewOrganization"
                                    @update:value="
                                        validator($event, addOrganizationInput)
                                    "
                                />
                                <EaiButton
                                    size="small"
                                    @click="addNewOrganization"
                                >
                                    Add
                                </EaiButton>
                            </div>
                        </template>
                    </EaiAccordionItem>
                    <EaiAccordionItem
                        :collapsed="true"
                        padding="py-2 pl-1 pr-0"
                    >
                        <template #header>
                            <span
                                class="grow text-md font-medium text-primary-100"
                            >
                                Rename current project
                            </span>
                        </template>
                        <template #body>
                            <div class="flex flex-row gap-2 items-start mt-2">
                                <EaiInput
                                    ref="renameOrganizationInput"
                                    v-model:value="
                                        updatedCurrentOrganizationName
                                    "
                                    class="w-4/5"
                                    placeholder="Enter name"
                                    @update:value="
                                        validator(
                                            $event,
                                            renameOrganizationInput
                                        )
                                    "
                                    @keyup.enter="renameOrganization"
                                />
                                <EaiButton
                                    size="small"
                                    @click="renameOrganization"
                                >
                                    Rename
                                </EaiButton>
                            </div>
                        </template>
                    </EaiAccordionItem>
                </div>
            </div>
        </template>
        <template #display="{ selectedValue }">
            <div class="flex">
                <VueFeather
                    class="cursor-pointer pr-5"
                    type="folder"
                />

                <!-- <span class="font-medium">Project: </span> -->
                <template v-if="isOpen">
                    <input
                        v-if="selectedValue"
                        v-model="searchString"
                        type="text"
                        class="w-full organization-list-search-input !text-primary-300"
                        @input="filterOrganizations(searchString)"
                    />
                    <span v-else>select</span>
                </template>
            </div>
        </template>
        <template #toggler="{ active }">
            <VueFeather
                v-show="isOpen"
                class="cursor-pointer self-strech"
                :type="active ? 'chevron-down' : 'chevron-up'"
            />
        </template>
    </EaiDropup>
</template>

<script setup lang="ts">
import { ref, watch, onMounted } from "vue"
import { storeToRefs } from "pinia"
import { useAuthStore } from "../resources/stores/auth"
import { apiRequest } from "@/resources/http/http"
import EaiInput from "@stories/Input/Input.vue"
import { debounce } from "ts-debounce"
import { useRoute, useRouter } from "vue-router"
import EaiDropup from "@stories/Dropup/EaiDropup.vue"
import EaiAccordionItem from "@stories/Accordion/EaiAccordionItem.vue"
import EaiButton from "@stories/Button/Button.vue"
import { Organization } from "@/resources/stores/types/auth"

withDefaults(defineProps<{ isOpen: boolean }>(), {
    isOpen: false,
})

const route = useRoute()
const router = useRouter()

const authStore = useAuthStore()
const { currentOrganization, isSuperAdmin } = storeToRefs(authStore)

const organizations = ref<Organization[]>([])
const searchString = ref(currentOrganization.value?.name || "")
const getOrganizationsList = async (
    searchTerm: string | null = null,
    adminView = false
) => {
    let payload: { admin_view: boolean; search_term?: string } = {
        admin_view: isSuperAdmin.value && adminView,
    }
    if (searchTerm) payload["search_term"] = searchTerm
    try {
        const response = await apiRequest.get("/v2/organizations", payload)
        return response.data
    } catch (error) {
        console.log(error)
    }
}
onMounted(async () => (organizations.value = await getOrganizationsList()))

const newOrganizationName = ref("")
const updatedCurrentOrganizationName = ref("")
const addOrganizationInput = ref<InstanceType<typeof EaiInput> | null>(null)
const renameOrganizationInput = ref<InstanceType<typeof EaiInput> | null>(null)
const adminView = ref(false)

const addNewOrganization = async (): Promise<void> => {
    try {
        await apiRequest.post("/v2/organizations", {
            name: newOrganizationName.value,
        })
        alert(`Successfully created project ${newOrganizationName.value}`)
        newOrganizationName.value = ""
        organizations.value = await getOrganizationsList()
    } catch (error) {
        alert(`Could not add project ${newOrganizationName.value}`)
    }
}

const renameOrganization = async (): Promise<void> => {
    if (!currentOrganization.value) throw "Current organization can't be null"
    try {
        await apiRequest.put(
            `/v2/organizations/${currentOrganization.value.id}/name`,
            { name: updatedCurrentOrganizationName.value }
        )
        currentOrganization.value.name = updatedCurrentOrganizationName.value
        alert(
            `Successfully renamed current project to ${updatedCurrentOrganizationName.value}`
        )
        updatedCurrentOrganizationName.value = ""
        await getOrganizationsList()
    } catch (error) {
        alert(`Could not add project ${newOrganizationName.value}`)
    }
}

const validateNewOrganizationName = async (name: string): Promise<string> => {
    try {
        await apiRequest.post("/v2/organizations/validate-name", { name })
        return ""
    } catch (error) {
        if (error.code === 400) {
            return error.message || "Invalid name"
        } else return "Invalid name"
    }
}

const validator = debounce(
    async (name: string, inputEl: InstanceType<typeof EaiInput>) => {
        inputEl.setErrorMessage("")
        const errorMessage = await validateNewOrganizationName(name)
        inputEl.setErrorMessage(errorMessage, true)
    },
    400
)

const { changeOrganization } = authStore
const updateOrganization = async (organizationId: string) => {
    try {
        if (
            route.name === "annotate-document" ||
            route.name === "document-view"
        )
            router
                .push({ name: "home" })
                // Change org in the callback because in annotation interface this should
                //  run only if the user has confirmed the popup.
                .then(async () => await changeOrganization(organizationId))
        else await changeOrganization(organizationId)
    } catch (error) {
        console.log(error)
        alert("Could not change organization.")
    }
}

watch(
    () => currentOrganization.value?.name,
    async (newValue) => {
        searchString.value = newValue || ""
    }
)

watch(adminView, async (newValue) => {
    organizations.value = await getOrganizationsList(
        searchString.value,
        newValue
    )
})

const onClickAway = async (): Promise<void> => {
    searchString.value = currentOrganization.value?.name || ""
}

const filterOrganizations = debounce(async (query: string) => {
    try {
        organizations.value = await getOrganizationsList(query, adminView.value)
    } catch (error) {
        console.log(error)
        alert("Could not load organization list")
    }
}, 200)
</script>

<style scoped>
@tailwind base;
@tailwind components;

@layer components {
    .organization-list-search-input {
        @apply !p-0 !bg-transparent !border-0 truncate !m-0 !appearance-none !shadow-[none];
        @apply active:!text-primary-100 focus:!text-primary-100;
    }

    .organization-list-search-input::-webkit-search-cancel-button {
        -webkit-appearance: none;
    }
}
</style>
