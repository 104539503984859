import { ref, computed } from "vue"
import { defineStore } from "pinia"
import { apiRequest } from "../http/http"
import { User, Organization, Role } from "./types/auth.d"

export const useAuthStore = defineStore("user", () => {
    const currentUser = ref<User | null>(null)
    const currentOrganization = ref<Organization | null>(null)
    const currentUserRole = ref<Role | null>(null)

    const csrfToken = ref<string | null>(null)
    const apiKey = ref<string | null>(null)

    const isLoggedIn = computed(() => currentUser.value != null)

    const isRegistrationComplete = computed(
        () =>
            currentUser.value &&
            currentUser.value.registered &&
            (currentUser.value?.nr_projects || 0) > 0
    )

    const isSuperAdmin = computed(
        () => currentUser.value?.is_superadmin === true
    )

    const isAdmin = computed(
        () => currentUserRole.value === Role.admin || isSuperAdmin.value
    )

    const getCsrfToken = async () => {
        try {
            const response = await apiRequest.get("/user/csrf")
            csrfToken.value = response.response.csrf_token
        } catch (error) {
            // TODO: redirect to error page
            console.log(error)
        }
    }
    const getApiKey = async () => {
        if (!currentOrganization.value) return
        try {
            apiKey.value = await apiRequest.get(
                `/v2/organizations/${currentOrganization.value.id}/api-key`
            )
        } catch (error) {
            console.log(error)
        }
    }
    const retrieveUser = async () => {
        currentUser.value = await apiRequest.get("/user/authenticate")
    }

    const logout = async () => {
        await apiRequest.post("/user/logout")

        currentUser.value = null
        currentOrganization.value = null
        currentUserRole.value = null

        window.location.href = "/"
    }

    const changeOrganization = async (newOrganizationId: string) => {
        const response = await apiRequest.put("/v2/organizations/current", {
            organization_id: newOrganizationId,
        })
        currentOrganization.value = response.current_organization
        currentUserRole.value = response.user_role
    }

    const getCurrentOrganization = async () => {
        const response = await apiRequest.get("/v2/organizations/current")
        currentOrganization.value = response.current_organization
        currentUserRole.value = response.user_role
    }

    const currentDocumentTypes = computed(() => {
        return currentOrganization.value?.document_types || []
    })
    return {
        currentUser,
        currentOrganization,
        currentUserRole,
        csrfToken,
        apiKey,
        isLoggedIn,
        isRegistrationComplete,
        isSuperAdmin,
        isAdmin,
        currentDocumentTypes,
        changeOrganization,
        getCsrfToken,
        getApiKey,
        retrieveUser,
        getCurrentOrganization,
        logout,
    }
})
