<template>
    <nav>
        <ol class="flex flex-row justify-start items-center h-8 gap-4 py-2">
            <template
                v-for="(crumb, i) in crumbs"
                :key="i"
            >
                <li
                    v-if="showDivider && i > 0"
                    class="text-gray-300 flex-none h-4"
                >
                    <VueFeather
                        type="chevron-right"
                        size="1rem"
                    />
                </li>
                <li
                    class="flex flex-row items-center text-sm font-medium text-gray-500 cursor-pointer last:text-primary-700 last:bg-primary-50 last:rounded-md last:cursor-default last:py-1 last:px-2 last:-ml-2"
                    @click="navigateTo(crumb)"
                    :class="crumb.name.length > 50 ? 'overflow-hidden' : ''"
                >
                    <VueFeather
                        v-if="crumb?.icon?.type"
                        :type="crumb.icon.type"
                        size="1rem"
                        class="mr-2"
                    />
                    <span class="text-ellipsis overflow-hidden slashed-zero">
                        {{ crumb.name }}
                    </span>
                </li>
            </template>
        </ol>
    </nav>
</template>

<script setup lang="ts">
import { RouteLocationRaw, useRoute, useRouter } from "vue-router"
import { computed } from "vue"

export interface BreadCrumb {
    name: string
    icon?: { [type: string]: string }
    to: RouteLocationRaw
}

const props = withDefaults(
    defineProps<{
        crumbMapping?: { [key: string]: BreadCrumb }
        homeName?: string
    }>(),
    { homeName: "home" }
)

const showDivider = true

const homeCrumb = computed(
    () =>
        ({
            name: props.homeName || "home",
            icon: { type: "home" },
            to: { name: "home" } as RouteLocationRaw,
        } as BreadCrumb)
)

const route = useRoute()
const crumbs = computed(() => {
    let breadCrumbs: BreadCrumb[] = [homeCrumb.value]
    if (route.meta.crumbs)
        breadCrumbs.push(...(route.meta.crumbs as BreadCrumb[]))

    // This is used to set the value of some breadcrumbs dynamically. For example
    //  the document and annotation views require the file name to be displayed
    //  as a breadcrumb and the document id to construct the link
    breadCrumbs = breadCrumbs.map((crumb) => {
        if (crumb.name.startsWith("%"))
            return props.crumbMapping?.[crumb.name.slice(1)] || crumb

        return crumb
    })
    return breadCrumbs
})

const router = useRouter()
const navigateTo = (crumb: BreadCrumb) => {
    if (crumb.to) router?.push(crumb.to)
}
</script>
