<template>
    <div class="flex flex-col justify-start items-start gap-1.5 grow">
        <label
            v-if="label"
            class="text-sm font-medium label grow-0 text-gray-700"
        >
            {{ label }}
        </label>
        <input
            v-model="inputValue"
            v-bind="$attrs"
            class="peer"
            ref="input"
            @input="debouncedValidateValue()"
        />
        <span
            class="text-xs font-normal text-error-500 hidden peer-invalid:block"
            v-if="validationMessage !== null"
        >
            {{ validationMessage }}
        </span>
    </div>
</template>

<script lang="ts">
export default {
    inheritAttrs: false,
    name: "EaiInput",
}
</script>

<script setup lang="ts">
import { ref, computed, watch } from "vue"
import { debounce } from "ts-debounce"

// returns a string with the error message; empty string if input is valid.
export type InputValidator = (inputValue: string) => string

export interface Props {
    value: string | null
    label?: string | null
    validator?: InputValidator | null
}
const props = withDefaults(defineProps<Props>(), {
    label: null,
    validator: null,
})

const emit = defineEmits<{
    (e: "update:value", value: string): void
}>()
const inputValue = computed({
    get: () => props.value,
    set: (value: string) => emit("update:value", value),
})

const input = ref<HTMLInputElement | null>(null)
/**
 * Helper method to set the HTML5 validation error message. Note that this can
 * be used by a parent component to set the error message when performing async
 * server-side validtaion.
 *
 * @param errorMessage the error message. Empty string means field is valid.
 * @param reportValidity if true displays popup with error.
 */
const setErrorMessage = (errorMessage: string, reportValidity = false) => {
    if (!input.value) throw "Input not initialised"
    input.value.setCustomValidity(errorMessage)
    if (reportValidity) input.value.reportValidity()
    validationMessage.value = errorMessage
}

defineExpose({ setErrorMessage })

const DEBOUNCE_TIMEOUT = 400 // msec
// This is needed because input.validationMessage is not reactive
const validationMessage = ref<string | null>(null)
const validateValue = () => {
    input.value?.setCustomValidity("")
    if (props.validator) setErrorMessage(props.validator(props.value || ""))
    validationMessage.value = input.value?.validationMessage ?? null
}
/**
 * Validate the input on user typing. Note that it's debounced so that the error
 * message is displayed only when the user stops typing.
 */
const debouncedValidateValue = debounce(validateValue, DEBOUNCE_TIMEOUT)
</script>

<style lang="css">
@import "./input.css";
</style>
